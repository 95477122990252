import React from "react"
import { graphql, Link  } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "../components/Elements/Img"
import SectionContainer from "../components/SectionContainer"
import VideoPlayer from "../components/VideoPlayer/index"
import { Container, Row, Col } from "../components/Grid"
import {
  SectionBody,
  AccentHeader,
  PageTitle,
} from "../components/Elements/index"

const WorkLink = styled(props => <Link {...props} />)`
  color: #414141;
  &:active {
    color: #414141;
  }

  &:visited {
    color: #414141;
  }
`


const Footer = styled.footer`
  background: #6a115a;
  display: flex;
  height: 120px;
  align-items: center;
`

const RevomeMarginImage = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const RevomeMarginVideo = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const VideoContainerFrame = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    margin: 0;
    width: 100%;
  }
  iframe {
    position: absolute;
    z-index: 999;
    bottom: 3%;
    width: 53% !important;
    height: 100% !important;
    margin-left: -1%;
  }
`

const RevomeMarginWrapper = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const genicos = ({ data }) => (
  <Layout>
    <ThemeProvider
      theme={{
        backgroundColor: "#FFFFFF",
        primaryColor: "#414141",
        dividerColor: "#EE7038;",
        invert: {
          backgroundColor: "#f2f2f2",
        },
      }}
    >
      <>
        <Seo title="Genicos" />
        <SectionContainer noBottomMargin>
          <Container>
            <AccentHeader size="large" background="#ffffff">
              <WorkLink to="/">
                  The Work
                </WorkLink>
            </AccentHeader>
            <div style={{ paddingBottom: "30px" }}>
            <PageTitle style={{ color: "#78c800" }}>Braving Oncology</PageTitle>
            </div>
            <Row>
              <Col md={4}>                
                <AccentHeader>The Problem</AccentHeader>                
                <SectionBody size="regular">
                  Overcoming the challenges of launching a product in oncology or rare diseases isn’t easy. GENICOS fuses in-depth scientific acumen with creative passion to dimensionalize the solutions our clients provide to healthcare professionals and patients. Our unique, proprietary approaches to strategy and ideas turn daunting complexities into stunning solutions. Few agencies can stake their claim in the challenging world of oncology—our revitalized brand reflects what it takes to do so.  <br />
                  <br />                  
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="regular">
                  <h1>If it doesn’t intimidate you a little in the beginning, it may not be worth it in the end.</h1>
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>

        <SectionContainer invert noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", color: "#5C5C5C" }}>
            <Row>
              <Col md={8}>                
                <SectionBody size="regular">
                  <h2 style={{ color: "#000000" }}>The Message Behind the Hero</h2><br/>
                  The hero image on our website is a video created of a T cell attacking, and ultimately destroying, a cancer cell. It’s the perfect representation of the battles our clients—and patients—face in fighting these diseases. Braving Oncology states our passion and how we turn products into brands and patients into survivors.
                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noBottomMargin>
          <Container>
            <Row>
              <Col md={4}>                
                <AccentHeader>The Idea</AccentHeader>                
                <SectionBody size="regular">
                  The idea was to first build a destination that both supports our brand and captures our desire to achieve great things. In order to do this, we built a microsite to tell our story, show some of our best work and ultimately produce leads. Through campaign case histories in specific disease states and tumor types, we wanted to transform site traffic into meaningful lasting impressions of our creative problem-solving. <br />
                  <br />                  
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="regular">
                  <h1>It’s our burning desire to do great things</h1>
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <Img fluid={data.image2.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", backgroundColor: "#ffffff", color: "#5C5C5C" }}>
            <Row>
              <Col md={8}>                
                <SectionBody size="regular">
                  <h2 style={{ color: "#000000" }}>The Experience to Master Complex, Dynamic Markets</h2><br/>
                  Our website showcases our experience, from 50+ product launches for over 50 tumor types to winning 180+ awards supporting over 100 sbrands. GENICOS is focused on oncology and rare diseases like no other agency.
                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <Img fluid={data.image3.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", backgroundColor: "#ffffff", color: "#5C5C5C" }}>
            <Row>
              <Col md={8}>
                <AccentHeader>The Execution</AccentHeader>
                <SectionBody size="regular">
                  We built our brand around the ideas of bravery and passion. The science of oncology is deep, the market is constantly changing and the approach must be all-in—so we launched a campaign that captures courage in oncology in a powerful visual language. Activating LinkedIn as our primary communication platform, we began targeting ads, creating videos, and posting content to drive traffic to our website and generate leads.                  
                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin>
          <RevomeMarginImage>
            <Img fluid={data.image4.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>

        {/*<SectionContainer noTopMargin noBottomMargin>
          <Container>
            <Row>
              <Col md={4}>
                <VideoContainerFrame>
                  <Img fluid={data.video1.childImageSharp.fluid} />
                  <iframe
                    src="https://player.vimeo.com/video/790570242?h=df70811304&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture; title"
                    allowfullscreen
                  ></iframe>
                </VideoContainerFrame>
              </Col>

              <Col md={4}>
                <VideoContainerFrame>
                  <Img fluid={data.video2.childImageSharp.fluid} />
                  <iframe
                    src="https://player.vimeo.com/video/790570274?h=30f721fe6e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture; title"
                    allowfullscreen
                  ></iframe>
                </VideoContainerFrame>
              </Col>
            </Row>
            </Container>
        </SectionContainer>*/}

        <SectionContainer noBottomMargin>
          <Container >
            <Row>
              <Col md={4}>
                <div className="video-adjustment special">
                  <RevomeMarginWrapper>
                    <VideoPlayer
                      poster={() => <Img fluid={data.video1.childImageSharp.fluid} />}
                      url="https://player.vimeo.com/video/790570242?h=df70811304&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    />
                  </RevomeMarginWrapper>
                </div>
              </Col>
              <Col md={4}>
              <div className="video-adjustment special">
                <RevomeMarginWrapper>
                  <VideoPlayer
                    poster={() => <Img fluid={data.video2.childImageSharp.fluid} />}
                    url="https://player.vimeo.com/video/790570274?h=30f721fe6e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  />
                </RevomeMarginWrapper>
                </div>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer  noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", backgroundColor: "#ffffff", color: "#5C5C5C" }}>
            <Row>
              <Col md={8}>
                <AccentHeader>The Results</AccentHeader>
                <SectionBody size="regular">
                  LinkedIn post engagement has increased to 5.89%, which is 3.8% above average. There’s been a significant increase in website traffic, session durations, new visitors from organic traffic and conversions since we launched the new website. Continued growth and strong awareness have become prevalent across all tactics significantly improving traffic and qualified leads. GENICOS is quickly becoming a leader in the oncology and rare disease marketing and advertising space.
                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>

        <img src="../images/2023/genicos/image7.svg" alt="" />

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <Img fluid={data.image7.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>

        <SectionContainer  noTopMargin noBottomMargin>
          <Container>
            <Row>
              <Col md={8}>
                <hr/>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>


      </>
    </ThemeProvider>
  </Layout>
)

export default genicos

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "2023/genicos/image1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image2: file(relativePath: { eq: "2023/genicos/image2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image3: file(relativePath: { eq: "2023/genicos/image3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image4: file(relativePath: { eq: "2023/genicos/image4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image5: file(relativePath: { eq: "2023/kimmtrak-connect/image5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image6: file(relativePath: { eq: "2023/kimmtrak-connect/image6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image7: file(relativePath: { eq: "2023/genicos/image7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    videoThumbnail: file(relativePath: { eq: "2023/kimmtrak-connect/banner1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    video1: file(relativePath: { eq: "2023/genicos/videobg1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    video2: file(relativePath: { eq: "2023/genicos/videobg2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
